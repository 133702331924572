export const uniqueBy = <T>(array: T[], key: keyof T) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (seen.has(value)) return false;
    seen.add(value);
    return true;
  });
};

export const sortBy = <T>(array: T[], keys: (keyof T)[]): T[] => {
  return [...array].sort((a, b) => {
    for (const key of keys) {
      const valueA = a[key];
      const valueB = b[key];

      if (valueA === valueB) continue;

      if (typeof valueA === "string" && typeof valueB === "string") {
        return valueA.localeCompare(valueB);
      }

      return (valueA ?? 0) < (valueB ?? 0) ? -1 : 1;
    }
    return 0;
  });
};
