import { airdoor, RailwayData } from "@airdoor/master-data";
import { getPrefecture, Prefecture } from "./Prefecture";
import { getAllRailwayCompanies, makeRailwayCompany, RailwayCompany } from "./RailwayCompany";
import { makeStation, Station } from "./Station";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Railway = {
  id: number;
  displayName: string;
  url: string;
  colorCode: string;
  majorStations: string[];
  prefectureSlugs: string[];
  queryKey: (typeof QUERY_KEY)["RAILWAY_ID"];
  query: { [QUERY_KEY.RAILWAY_ID]: string };
  displayOrder: number;
  stationIds: number[];
  stations: Station[];
  prefectures: () => Prefecture[];
  railwayCompany: RailwayCompany;
};
export type IndexableRailway = Railway & { query: { [key: string]: string }; url: string };

export const getRailway = (railwayId: number): Railway | undefined => {
  try {
    const railway = airdoor.railways.get(railwayId);
    return makeRailway(railway);
  } catch (e) {
    // 登録のない沿線の可能性があるので
  }
};
export const getRailwayStrict = (railwayId: number): Railway => {
  const railway = airdoor.railways.get(railwayId);
  return makeRailway(railway);
};
export const getRailwayByLegacyCode = (legacyCode: string): Railway => {
  const railway = airdoor.legacyRailways.get(legacyCode);

  return makeRailway(railway);
};

export const getAllRailways = (prefectureSlugs?: string[]): Railway[] => {
  return getAllRailwayCompanies(prefectureSlugs).flatMap((rc) => rc.railways);
};

/**
 * @param stationIds
 * @param prefectureSlug 指定すると特定の沿線ないの駅のみに絞り込み可能
 * @returns
 */
export const getFulfilledRailways = (stationIds: number[], jisX_0401?: string): Railway[] => {
  if (stationIds.length < 1) return [];

  const railways = airdoor.prefectures
    .all()
    .filter((p) => !jisX_0401 || p.jisX_0401 === jisX_0401)
    .flatMap((p) => p.railwayCompanies.flatMap((c) => c.railways));

  // 都道府県指定のある場合は、該当都道府県に含まれる駅のみで判定する
  return railways
    .filter((r) => {
      const stations = r.stations.filter(
        (s) => !jisX_0401 || s.jisX_0401_0402.startsWith(jisX_0401),
      );
      return stations.length > 0 && stations.every((s) => stationIds.includes(s.id));
    })
    .map((r) => makeRailway(r));
};

/**
 * jisCode　に一致する駅が一つでも含まれる沿線を取得する
 */
export const getRailwaysByJisCode = (jisX_0401_0402: string): Railway[] => {
  const { prefecture } = airdoor.municipalities.get(jisX_0401_0402);
  const allRailways: RailwayData[] = prefecture.railwayCompanies.flatMap(
    (company) => company.railways,
  );

  const relatedRailways = allRailways.filter((railway) =>
    railway.stations.some((station) => station.jisX_0401_0402 === jisX_0401_0402),
  );

  return relatedRailways.map((railway) => makeRailway(railway));
};

export const makeRailway = (data: RailwayData) => {
  return {
    id: data.id,
    displayName: data.name,
    colorCode: data.colorCode,
    majorStations: data.majorStations.map((s) => s.name),
    prefectureSlugs: data.prefectureSlugs,
    displayOrder: data.displayOrder,
    stationIds: data.stationIds,
    queryKey: QUERY_KEY.RAILWAY_ID,
    get query() {
      return { [this.queryKey]: String(data.id) };
    },
    get url() {
      return `/condition/route/r-${this.id}`;
    },
    get stations(): Station[] {
      return data.stations.map((s) => makeStation(s));
    },
    prefectures(): Prefecture[] {
      return data.prefectureSlugs.map((slug) => getPrefecture({ slug }));
    },
    get railwayCompany() {
      return makeRailwayCompany(data.railwayCompany);
    },
  };
};
