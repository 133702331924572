import { airdoor, RailwayCompanyData } from "@airdoor/master-data";
import { makeRailway, Railway } from "./Railway";
import { sortBy, uniqueBy } from "~/utils/array";

export type RailwayCompany = {
  id: number;
  name: string;
  displayOrder: number;
  railways: Railway[];
};

export const getRailwayCompany = (railwayCompanyId: number): RailwayCompany => {
  const data = airdoor.railwayCompanies.get(railwayCompanyId);
  return makeRailwayCompany(data);
};

export const getAllRailwayCompanies = (prefectureSlugs?: string[]): RailwayCompany[] => {
  const prefectures = prefectureSlugs
    ? prefectureSlugs.map((slug) => airdoor.prefectures.get(slug))
    : airdoor.prefectures.all();
  const railwayCompanies = uniqueBy(
    prefectures.flatMap((p) => p.railwayCompanies),
    "id",
  );
  return railwayCompanies.map((c) => makeRailwayCompany(c));
};

export const makeRailwayCompany = (data: RailwayCompanyData): RailwayCompany => {
  return {
    id: data.id,
    name: data.name,
    displayOrder: data.displayOrder,
    get railways() {
      return sortBy(data.railways, ["displayOrder"]).map((r) => makeRailway(r));
    },
  };
};
