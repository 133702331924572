import { airdoor, MunicipalityGroupData } from "@airdoor/master-data";
import { makeMunicipality, Municipality } from "./Municipality";
import { makePrefecture, Prefecture } from "./Prefecture";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

type MunicipalityGroupBase = {
  slug: string;
  jisX_0401: string;
  prefectureSlug: string;
  displayName: string;
  crumbName: string;
  queryKey: (typeof QUERY_KEY)["MUNICIPALITY_GROUP_ID"];
  jisX_0401_0402Codes: string[];
  municipalities: Municipality[];
  prefecture: Prefecture;
};

export type QueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: number;
  query: { [QUERY_KEY.MUNICIPALITY_GROUP_ID]: string };
  url: string;
};
export type NotQueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: undefined;
  query: undefined;
  url: undefined;
};

export type MunicipalityGroup = QueryableMunicipalityGroup | NotQueryableMunicipalityGroup;

export function getMunicipalityGroup(where: { id: number }): QueryableMunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string }): MunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string } | { id: number }): MunicipalityGroup {
  const municipalityGroup =
    "slug" in where
      ? airdoor.municipalityGroups.get(where.slug)
      : airdoor.municipalityGroups.getById(where.id);
  return makeMunicipalityGroup(municipalityGroup);
}

export function getFulfilledGroups(jisCodes: string[]): MunicipalityGroup[] {
  if (jisCodes.length < 1) return [];

  const groups = airdoor.municipalityGroups.all();
  return groups
    .filter((group) => group.municipalityJisCodes.every((jisCode) => jisCodes.includes(jisCode)))
    .map((g) => makeMunicipalityGroup(g));
}

export const getAllMunicipalityGroup = () => {
  return airdoor.municipalityGroups.all().map((mg) => makeMunicipalityGroup(mg));
};

export const makeMunicipalityGroup = (data: MunicipalityGroupData): MunicipalityGroup => {
  const base = {
    slug: data.slug,
    jisX_0401: data.prefecture.jisX_0401,
    prefectureSlug: data.prefectureSlug,
    displayName: data.name,
    crumbName: data.shortName || data.name,
    queryKey: QUERY_KEY.MUNICIPALITY_GROUP_ID,
    jisX_0401_0402Codes: data.municipalityJisCodes,
    get municipalities() {
      return data.municipalities.map((m) => makeMunicipality(m));
    },
    get prefecture() {
      return makePrefecture(data.prefecture);
    },
  };

  if (data.id) {
    return {
      ...base,
      id: data.id,
      get query() {
        return { [QUERY_KEY.MUNICIPALITY_GROUP_ID]: String(data.id) };
      },
      get url() {
        return `/list?${new URLSearchParams(this.query).toString()}`;
      },
    };
  }

  return {
    ...base,
    id: undefined,
    query: undefined,
    url: undefined,
  };
};
