import { airdoor, PrefectureData } from "@airdoor/master-data";
import { makeMunicipality, Municipality } from "./Municipality";
import { makeRailwayCompany, RailwayCompany } from "./RailwayCompany";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";
import { sortBy } from "~/utils/array";

export type Prefecture = {
  slug: string;
  jisX_0401: string;
  displayName: string;
  municipalityGroupSlugs: string[];
  queryKey: (typeof QUERY_KEY)["PREFECTURE_SLUG"];
  query: { [QUERY_KEY.PREFECTURE_SLUG]: string };
  url: string;
  pageUrl: { top: string; region: string; route: string };
  railwayCompanies: RailwayCompany[];
  municipalities: Municipality[];
};

export function getPrefecture(where: { jisX_0401: string }): Prefecture;
export function getPrefecture(where: { slug: string }): Prefecture;
export function getPrefecture(where: { jisX_0401: string } | { slug: string }): Prefecture {
  const pref =
    "slug" in where
      ? airdoor.prefectures.get(where.slug)
      : airdoor.prefectures.getByJisCode(where.jisX_0401);

  return makePrefecture(pref);
}
export function getFulfilledPrefectures(groupSlugs: string[]): Prefecture[] {
  if (groupSlugs.length < 1) return [];

  const prefectures = airdoor.prefectures.all();
  return prefectures
    .filter((pref) => pref.municipalityGroupSlugs.every((slug) => groupSlugs.includes(slug)))
    .map((p) => makePrefecture(p));
}

export const getAllPrefectures = () => {
  return airdoor.prefectures.all().map((p) => makePrefecture(p));
};

export const makePrefecture = (data: PrefectureData): Prefecture => {
  return {
    jisX_0401: data.jisX_0401,
    slug: data.slug,
    displayName: data.name,
    queryKey: QUERY_KEY.PREFECTURE_SLUG,
    municipalityGroupSlugs: data.municipalityGroupSlugs,
    get query() {
      return { [this.queryKey]: data.slug };
    },
    get url() {
      return `/list?${new URLSearchParams(this.query).toString()}`;
    },
    get pageUrl() {
      const baseUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}/${data.slug}`;
      return {
        top: `${baseUrl}/`,
        region: `${baseUrl}/region`,
        route: `${baseUrl}/route`,
      };
    },
    get railwayCompanies() {
      return sortBy(data.railwayCompanies, ["displayOrder"]).map((rc) => makeRailwayCompany(rc));
    },
    get municipalities() {
      return data.municipalityGroups
        .flatMap((g) => g.municipalities)
        .map((m) => makeMunicipality(m));
    },
  };
};
