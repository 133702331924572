import { airdoor, MunicipalityData } from "@airdoor/master-data";
import { makeMunicipalityGroup, MunicipalityGroup } from "./MunicipalityGroup";
import { makePrefecture, Prefecture } from "./Prefecture";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Municipality = {
  jisX_0401_0402: string;
  displayName: string;
  queryKey: (typeof QUERY_KEY)["JIS_X_0401_0402"];
  query: { [QUERY_KEY.JIS_X_0401_0402]: string };
  url: string;
  prefectureSlug: string;
  municipalityGroupSlug: string;
  prefecture: Prefecture;
  municipalityGroup: MunicipalityGroup;
};

export const getMunicipality = (jisX_0401_0402: string): Municipality => {
  const normalizeId = normalizeMunicipalityId(jisX_0401_0402);
  const m = airdoor.municipalities.get(normalizeId);

  return makeMunicipality(m);
};

export const makeMunicipality = (m: MunicipalityData): Municipality => {
  return {
    jisX_0401_0402: m.jisX_0401_0402,
    displayName: m.name,
    queryKey: QUERY_KEY.JIS_X_0401_0402,
    get query() {
      return { [this.queryKey]: m.jisX_0401_0402 };
    },
    get url() {
      return `/list?${new URLSearchParams(this.query).toString()}`;
    },
    prefectureSlug: m.prefectureSlug,
    municipalityGroupSlug: m.municipalityGroupSlug,
    get prefecture() {
      return makePrefecture(m.prefecture);
    },
    get municipalityGroup() {
      return makeMunicipalityGroup(m.municipalityGroup);
    },
  };
};

/**
 * 6桁のコードを5桁に変換する関数
 * @param {string} id - 6桁のコード（チェックディジット付き）
 * @returns {string} 5桁の全国地方公共団体コード
 */
export const normalizeMunicipalityId = (id: string): string => {
  return id.length === 6 ? id.slice(0, -1) : id;
};
