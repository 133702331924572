import { airdoor, StationData } from "@airdoor/master-data";
import { makeMunicipality, Municipality } from "./Municipality";
import { makeRailway, Railway } from "./Railway";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Station = {
  id: number;
  jisX_0401_0402: string;
  railwayId: number;
  name: string;
  displayName: string;
  legacyCode: string | undefined;
  queryKey: (typeof QUERY_KEY)["STATION_ID"];
  query: { [QUERY_KEY.STATION_ID]: string };
  criteriaQuery: { [QUERY_KEY.STATION_ID]: string };
  url: string;
  canonicalStation: Station;
  railway: Railway;
  nearbyStations: Station[];
  relatedStations: Station[];
  municipality: Municipality | undefined;
};
export type IndexableStation = Station & { query: { [QUERY_KEY.STATION_ID]: string }; url: string };

export const getStation = (stationId: number): Station | undefined => {
  try {
    const station = airdoor.stations.get(stationId);
    return makeStation(station);
  } catch (e) {
    // 登録のない駅の可能性があるので
  }
};
export const getStationStrict = (stationId: number): Station => {
  const station = airdoor.stations.get(stationId);
  return makeStation(station);
};
export const getStationByLegacyCode = (legacyCode: string): Station => {
  const station = airdoor.legacyStations.get(legacyCode);

  return makeStation(station, legacyCode);
};

export const makeStation = (data: StationData, legacyCode?: string) => {
  const legacyStationCode: string | undefined =
    legacyCode || airdoor.legacyStations.getLegacyCode(data.id);

  // NOTE: サイト内の駅リンクには、必ず代表駅（canonicalStation）のqueryもしくはurlを利用する。
  const canonicalStation = data.canonicalStation;

  return {
    id: data.id,
    jisX_0401_0402: data.jisX_0401_0402,
    railwayId: Number(data.railwayId),
    name: data.name,
    displayName: `${data.name}駅`,
    queryKey: QUERY_KEY.STATION_ID,
    legacyCode: legacyStationCode,
    get query() {
      return { [this.queryKey]: String(data.canonicalStation.id) };
    },
    get criteriaQuery() {
      // 検索条件用
      return { [this.queryKey]: String(data.id) };
    },
    get url() {
      return `/list?${new URLSearchParams(this.query).toString()}`;
    },
    get railway(): Railway {
      return makeRailway(data.railway);
    },
    get canonicalStation() {
      return makeStation(canonicalStation);
    },
    get nearbyStations() {
      return data.nearbyStations.map((s) => {
        return makeStation(s);
      });
    },
    get relatedStations() {
      return data.relatedStations.map((s) => makeStation(s));
    },
    get municipality() {
      try {
        return makeMunicipality(data.municipality);
      } catch (e) {
        if (e instanceof ReferenceError) {
          return;
        }

        throw e;
      }
    },
  };
};
